import { useState, useEffect } from "react";
import "./blogs.css";
// import { BlogsDataTable } from "views/admin/default/variables/columnsData";
// import tableDataComplex from "../default/variables/tableDataComplex.json";
// import ComplexTable from "views/admin/default/components/ComplexTable";
import { getAllBlog } from "proxyUrl";
import BlogTable from "./BlogTable";
// import { GetBookingDataByUserId } from "proxyUrl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
const Blogs = () => {
  const [blogData, setBlogData] = useState("");

  // console.log(user)
  const currentUser = useSelector((state) => state.user.user.userRole.name);
  console.log(currentUser);
  const fetchData = () => {
    axios
      .get(getAllBlog, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((blogdata) => {
        const blogData = blogdata.data;
        setBlogData(blogData);
      });
  };
  useEffect(() => {
    fetchData();
    // const userData = JSON.parse(localStorage.getItem("user"));
    // setUserItem(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(user.length);
  return (
    <>
      <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
        <div className="text-xl font-bold text-white  dark:text-white">
          All Blog
        </div>
        <Link to="/admin/blogs/add">
          <button className="rounded-full bg-white p-1  px-3 text-navy-800 dark:bg-navy-900 dark:text-white">
            Add Blog
          </button>
        </Link>
      </div>
      <div className="relative -mt-7  grid w-full grid-cols-1 gap-5 md:grid-cols-1">
        {/* <TotalSpent /> */}
        {/* <WeeklyRevenue /> */}
        {blogData ? (
          <>
          <BlogTable
           
            columnLenghth={blogData.length}
            tableData={blogData}
            editRoute={`/admin/blogs/edit`}
            deleteRoute={`/admin/blogs/delete`}
          />
        </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Blogs;
