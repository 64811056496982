/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { UpdateBrochure } from "proxyUrl";
import { GetSingleBrochure } from "proxyUrl";
import { UploadPdf } from "proxyUrl";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function VlBrochuresEdit() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);
  const [brochureType, setBrochureType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState("pending");
  const router = useNavigate();

  useEffect(() => {
    fetchBrochure();
  }, []);

  const fetchBrochure = async () => {
    const brochureId = id;
    try {
      const response = await axios.get(`${GetSingleBrochure}${brochureId}`);
      const { name, fileUrl, brochure_type } = response.data;
      console.log("response data fetching brochure");
      setName(name);
      setFileUrl(fileUrl);
      setBrochureType(brochure_type);
    } catch (error) {
      console.error("Error fetching brochure:", error);
      setErrorMessage("Failed to fetch brochure. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    console.log(e, "pdf file");

    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      setUploadStatus("uploading");
      const formData = new FormData();
      formData.append("pdf", file);
      const response = await axios.post(UploadPdf, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("PDF uploaded successfully:", response.data);
      setFileUrl(response.data.url);
      setUploadStatus("uploaded");
    } catch (error) {
      console.error("Error uploading PDF:", error);
      setErrorMessage("Failed to upload PDF. Please try again.");
    }
  };
  console.log(fileUrl, "file URL");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const brochureId = id;

    try {
      const response = await axios.put(
        `${UpdateBrochure}/${brochureId}`,
        {
          name: name,
          file_url: fileUrl,
          brochure_type: brochureType,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Brochure updated successfully:", response.data);
      setErrorMessage("");
      setTimeout(() => {
        router("/admin/vlBrochures");
      }, 3000);
    } catch (error) {
      console.error("Error updating brochure:", error);
      setErrorMessage("Failed to update brochure. Please try again.");
    }
  };

  return (
    <div>
      {/* <h1>Edit Brochure</h1> */}
      <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
        <div className="text-xl font-bold text-white  dark:text-white">
          Edit Brochure
        </div>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form
        onSubmit={handleSubmit}
        className="relative mt-7 grid w-full grid-cols-1 gap-3 bg-white py-10 dark:bg-navy-800 md:grid-cols-1"
      >
        <div className="mx-10">
          <label>Name:</label>
          <input
            className="my-3 w-full border-2 border-gray-800 py-1 px-2"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mx-10">
          <label>File URL:</label>
          <input
            className="my-3 w-full border-2 border-gray-800 py-1 px-2"
            type="file"
            onChange={handleFileChange}
            accept=".pdf"
          />
          {/* <button className="px-5 py-2 bg-primaryBlue text-white" type="button" onClick={handleUpload}>
            Upload PDF
          </button> */}
          <button
            className={`bg-primaryBlue px-5 py-2 text-white ${
              uploadStatus === "uploaded" ? "disabled" : ""
            }`}
            type="button"
            onClick={uploadStatus !== "uploaded" && handleUpload}
          >
            {uploadStatus === "uploaded" ? "Uploaded" : "Upload PDF"}
          </button>
        </div>
        <div className="mx-10">
          <label>Brochure Type:</label>
          <input
            className="my-3 w-full border-2 border-gray-800 py-1 px-2"
            type="text"
            value={brochureType}
            onChange={(e) => setBrochureType(e.target.value)}
            required
          />
        </div>
        <button
          className="my-4 mx-10 w-fit bg-primaryBlue px-5 py-2 text-white"
          type="submit"
        >
          Update Brochure
        </button>
      </form>
    </div>
  );
}
