import Banner from "./components/Banner";
import General from "./components/General";
import { useSelector } from "react-redux";

const ProfileOverview = () => {

  const userInfo = useSelector((state) => state.user.user)
  return (
    <>
    <div className="flex w-full flex-col gap-5">
       <div className="w-ful mt-3 flex h-fit  gap-5 ">
        <div className="w-[50%] lg:!mb-0">
          <Banner userInfo={userInfo} />
        </div>
              <div className=" w-[50%] col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <General userInfo={userInfo} />
        </div>

      </div> 
      
      <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          {/* <Project /> */}
        </div>
       

        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          {/* <Notification /> */}
        </div>
      </div>
    </div>

   
    </>
  );
};

export default ProfileOverview;
