import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/Sidebar";
// import Footer from "components/footer/Footer";
import { useSelector } from "react-redux";
// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
// import DataTables from "views/admin/tables";
import SignIn from "views/auth/SignIn";
import UserAdd from "views/admin/user/UserAdd";
import User from "views/admin/user/User";
import UserEdit from "views/admin/user/UserEdit";
import UserDelete from "views/admin/user/UserDelete";
import BlogsFormEdit from "views/blogs/BlogFormEdit";
import BlogDelete from "views/blogs/DeleteBlog";
// Icon Imports
import { MdHome, MdPerson } from "react-icons/md";
import { ImBlog } from "react-icons/im";
import { FaUserFriends } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { IoNewspaperSharp } from "react-icons/io5";
import SignOut from "views/auth/SignOut";
import BlogsForm from "views/blogs/BlogsForm";
import Blogs from "views/blogs/Blogs";
import VlBrochures from "views/vl_brochures/VlBrochures";
import VlBrochuresEdit from "views/vl_brochures/VlBrochuresEdit";
import { getUserDataByToken } from "proxyUrl";
import ErrorPaheInner from "views/ErrorPaheInner";
import axios from "axios";
import Apitesting from "./Apitesting";

import AcefreightBrochures from "views/acefrieghtExperts/AcefreightBrochures";
import AcefreightBrochuresEdit from "views/acefrieghtExperts/AcefreightBrochuresEdit";

export default function Admin(props) {
  const [permission, setPermission] = useState();
  const userDataByApi = async () => {
    await axios
      .get(getUserDataByToken, {
        withCredentials: true,
      })
      .then((res) => {
        setPermission(res.data.user.userPermission);
        return res;
      });
  };

  const routesLinks = [
    {
      name: "Dashboard",
      layout: `/admin`,
      path: "dashboard",
      icon: <MdHome className="h-6 w-6" />,
      display: "",
      component: <MainDashboard />,
    },
    {
      name: "edit user",
      layout: "/admin",
      path: "testing",
      display: "hidden",
      icon: "",
      component: <Apitesting />,
    },
  ];
  useEffect(() => {
    userDataByApi();
  }, []);
  // generate routes ***************************

  const logged_user_info = useSelector((state) => state.user.user);
  if (!logged_user_info.userPermission) {
  }
  const userPermissions = logged_user_info.userPermission
    ? logged_user_info.userPermission
    : permission;
  if (userPermissions) {
    userPermissions.forEach((element) => {
      if (element === "userAdd") {
        routesLinks.push(
          {
            name: "Users",
            layout: `/${`admin`}`,
            icon: <FaUserFriends className="h-6 w-6" />,
            path: "users",
            display: "",
            component: <User />,
          },
          {
            name: "add user",
            layout: `/${`admin`}`,
            path: "users/add",
            display: "hidden",
            icon: "",
            component: <UserAdd />,
          }
        );
      } else if (element === "userEdit") {
        routesLinks.push({
          name: "edit user",
          layout: `/${`admin`}`,
          path: "users/edit/:id",
          display: "hidden",
          icon: "",
          component: <UserEdit />,
        });
      } else if (element === "userDelete") {
        routesLinks.push({
          name: "edit user",
          layout: `/${`admin`}`,
          path: "users/delete/:id",
          display: "hidden",
          icon: "",
          component: <UserDelete />,
        });
      } else if (element === "blog") {
        routesLinks.push(
          {
            name: "Blogs",
            layout: `/${`admin`}`,
            path: "blogs",
            display: "",
            icon: <ImBlog className="h-6 w-6" />,
            component: <Blogs />,
          },
          {
            name: "BlogAdd",
            layout: `/${`admin`}`,
            path: "blogs/add",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <BlogsForm />,
          },
          {
            name: "BlogEdit",
            layout: `/${`admin`}`,
            path: "blogs/edit/:id",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <BlogsFormEdit />,
          },
          {
            name: "BlogDelete",
            layout: `/${`admin`}`,
            path: "blogs/delete/:id",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <BlogDelete />,
          }
        );
      } else if (element === "vlBrochures") {
        routesLinks.push(
          {
            name: "vlBrochures",
            layout: `/${`admin`}`,
            path: "vlBrochures",
            display: "",
            icon: <IoNewspaperSharp className="h-6 w-6" />,
            component: <VlBrochures />,
          },
          {
            name: "vlBrochuresEdit",
            layout: `/${`admin`}`,
            path: "vlBrochures/edit/:id",
            display: "hidden",
            icon: <IoNewspaperSharp className="h-6 w-6 text-white" />,
            component: <VlBrochuresEdit />
          }
        );
      }
      // 
      else if (element === "acefreightBrochures") {
      routesLinks.push(
        {
          name: "Acefreight Brochures",
          layout: `/${`admin`}`,
          path: "acefreightBrochures",
          display: "",
          icon: <IoNewspaperSharp className="h-6 w-6" />,
          component: <AcefreightBrochures />,
        },
        {
          name: "Acefreight BrochuresEdit",
          layout: `/${`admin`}`,
          path: "acefreightBrochures/edit/:id",
          display: "hidden",
          icon: <IoNewspaperSharp className="h-6 w-6 text-white" />,
          component: <AcefreightBrochuresEdit />
        }
      );
    }
    });
  }
  routesLinks.push(
    {
      name: "Profile",
      layout: `/${`admin`}`,
      path: "profile",
      display: "",
      icon: <MdPerson className="h-6 w-6" />,
      component: <Profile />,
    },
    {
      name: "Log In",
      layout: "/auth",
      path: "sign-in",
      display: "hidden",
      icon: <AiOutlineLogout className="h-6 w-6" />,
      component: <SignIn />,
    },
    {
      name: "Log Out",
      layout: "/auth",
      path: "sign-out",
      display: "",
      icon: <AiOutlineLogout className="h-6 w-6" />,
      component: <SignOut />,
    }
  );

  // generate routes ***************************

  const { ...rest } = props;
  const [open, setOpen] = useState(true);

  const [currentRoute, setCurrentRoute] = useState("Dashboard");

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getActiveRoute = (routes) => {
    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    getActiveRoute(routesLinks);
  });

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full bg-gray-200 pb-8 ">
      {/* <div className="w-full h-9 z-30 bg-navy-600 fixed"></div> */}
      <Sidebar
        open={open}
        routes={routesLinks}
        onClose={() => setOpen(false)}
      />
      {/* Navbar & Main Content */}
      <div className="h-full w-full  dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={" Creed Infotech"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routesLinks)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {routesLinks.map((prop, key) => {
                  return (
                    <Route
                      path={`/${prop.path}`}
                      element={prop.component}
                      key={key}
                    />
                  );
                })}
                <Route
                  path="/auth/sign-in"
                  element={<navigate to="/admin/Dashboard" replace />}
                />
                <Route path="/*" element={<ErrorPaheInner />} />
              </Routes>
            </div>
            {/* <div className="p-3">
              <Footer />
            </div> */}
          </div>
        </main>
      </div>
    </div>
  );
}
