import { useEffect } from "react";
import { HiX } from "react-icons/hi";
import Links from "./componentsrtl/Links";
import "./animation.css";
// import SidebarCard from "./componentsrtl/SidebarCard";
// import routes from "../../admin_routes.js";

import { useSelector } from "react-redux";

const Sidebar = ({ open, onClose, routes }) => {
  // const [navBack, setNavBack] = useState("sidebar-Wrapper");
  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    if (theme.isDark) {
      // setNavBack("sidebar-WrapperDark")
    } else {
      // setNavBack("sidebar-Wrapper")
    }
  }, [theme.isDark]);
  return (
    <div
      className={`sm:none  duration-175 shadow-white/9  fixed !z-50  m-4 flex min-h-[96vh] flex-col overflow-hidden rounded-xl bg-primaryBlue px-6  pb-10 shadow-2xl transition-all dark:bg-navy-800  dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>
      <div className={`mx-[56px] mt-[50px] flex items-start justify-start`}>
        <div className="mt-1 ml-1 h-1 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          {/* Creed_Infotech <span className="font-medium">TRAVAL</span> */}
          <img className="mt-[-30px]" src="/vlogo.png" alt="" width={120} />
        </div>
      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}
      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>
     
      {/* Nav item end */}
      <div className="navbar-flight absolute w-[100%] h-[260px] mx-auto flex items-center justify-center  top-[66%] left-0 p-4">
        <div className=" w-full h-full mx-auto   rounded-xl">
          {" "}
          {/* <img src="/myimage.png" className=" object-cover w-full h-full" alt="" /> */}
        </div>
      </div>
     
    </div>
  );
};

export default Sidebar;
